import React, { useEffect, useState } from "react";
import TacitBaseModal from "../../ant/TacitbaseModal";
import { Overlay } from "../hook/Overlay";
import {
  currentApplicant,
  List,
  notificationServiceEntityDotRemovePayloadType,
  Opening,
  Scope,
} from "../../type/type.d";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, Divider, Pagination, Spin, Typography } from "antd";
import { ArrowLeftOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { NOTIFY_ARCHIVE, TACITBASE, TrackerRoute } from "../../constant";
import Read from "../../Read";
import { Dispatch } from "redux";
import {
  clearCardData,
  initCurrentApplicant,
  unread_dot_remover,
} from "../../redux/actions/opening/action";
import { getApplicant, readNotification } from "../../lib/api";
import SideSectionComponent from "./cardSideSection/sideMenu/SideSectionComponent";
import ActivityTab from "./middleSection/ActivityTab";
import { LabeledIconButton } from "../../ant/Button";
import CardLinkedDetails from "./CardLinkedDetails";
const {Text} = Typography;
type CardProps = {
  currentApplicant: currentApplicant;
  initCurrApplicant(applicant: currentApplicant): void;
  removeCardNotificationDot(
    payload: notificationServiceEntityDotRemovePayloadType
  ): void;
  clearData: () => void;
  currentOpening: Opening;
  applicationId: string;
  notificationCenter: any;
  boardList :List[]
};
const Card = (props: CardProps) => {
  const [listName ,setListName] = useState("")
  const [loading, setLoading] = useState(false);
  const [total,setTotal] = useState<number>(0)
  const history = useHistory();
  const params: { board_id: string;card_id:string; candidate_id: string; tabname: string } = useParams()

  const itemRender = (current: any, type: any) => {
    if (type === "prev") {
      return (
        <LabeledIconButton
          icon={<LeftOutlined />}
          onClick={() => {}}        
          label=""
        />
      );
    }
    if (type === "next") {
      return (
        <LabeledIconButton
          icon={<RightOutlined />}
          onClick={() => {}}
          label=""
        />
      );
    }

    if (type === "page") {
      return null;
    }
    return null;
  };

  const handleCancel = () => {
    history.push(
      `/o/${props.applicationId}/b/${props.currentOpening.id}/${TrackerRoute}`
    );
  };
  
  useEffect(() => {
    if (params.card_id && params.card_id !== props.currentApplicant?.id) {
      getApplicant(params.card_id)
        .then((res: any) => {
          const applicant: currentApplicant = res;
  
          // Update content without affecting modal visibility
          document.title = `${applicant.name.charAt(0).toUpperCase()}${applicant.name.slice(1)} on ${props.currentOpening.name.charAt(0).toUpperCase()}${props.currentOpening.name.slice(1)} | ${TACITBASE}`;
          props.initCurrApplicant(applicant);
          if (props.notificationCenter.unreadDotsofServices?.ats?.cards[params.card_id]) {
            readNotification(params.card_id, "card").then(() => {
              props.removeCardNotificationDot({
                service: "ats",
                model_name: "card",
                remove_id: params.card_id,
              });
            });
          }
        })
        .catch((err: Error) => {
          console.log(err);
        });
    }
    return () => {
      document.title = `${props.currentOpening.name} | ${TACITBASE} `;
      props.clearData();
    };
  }, [params.card_id])

  useEffect(() => {
    if (props.currentApplicant && props.boardList) {
      const list = Object.values(props.boardList).filter(list =>list.id === props.currentApplicant.list_id)
      if(list[0]){
        setListName(list[0]?.name);
        let totalApplicant = list[0]?.applicants.length;
        setTotal(totalApplicant)
      }
    }
  }, [props.boardList, props.currentApplicant])
  
  const handleTableChange = (current: number) => {
    setLoading(true);
    setTimeout(() => {
    const list = Object.values(props.boardList).filter(list =>list.id === props.currentApplicant.list_id)
    const newApplicantId = list[0].applicants[current-1].id;
    if (newApplicantId) {
      setLoading(false); 
      history.replace({
        pathname: `/o/${props.applicationId}/b/${params.board_id}/tracker/${newApplicantId}`,
      });
    }else{
      setLoading(false);
    }
  },200);
  };  
  return (
    <>
      <TacitBaseModal
        keyboard={false}
        maskClosable={false}
        visibility={true}
        width={"100%"}
        title={
          <>
            <Overlay onClose={handleCancel} scope={Scope.Dialog}>
              <div
              className={`w-full flex justify-between py-2.5 px-4 ${
                props.currentApplicant.archive  && "bg-yellow-50"
              }`}
                style={{ height: 52 }}
              >
                <div>
                  <LabeledIconButton
                    icon={<ArrowLeftOutlined />}
                    type="text"
                    className="p-0"
                    label="Back to tracker"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCancel();
                    }}
                  />
                </div>
                {props.currentApplicant && props.currentApplicant.archive && (
                  <Alert
                  style={{}}
                  className="m-0 px-2 py-0"
                  message={NOTIFY_ARCHIVE}
                  type="warning"
                />
                )}
               <div className="flex flex-row ">
                  <div className="flex gap-2 items-center h-7 ">
                  {listName &&( <Text strong>{`In list ${listName}`}</Text>)}
                    
                    <Divider type="vertical" />
                  </div>{" "}
                  <div className="flex items-center ">
                  <Pagination
                  showTotal={(total, range) =>
                    `${range[0]} of ${total} cards`
                  } 
                  style={{alignItems:'center'}}
                  showQuickJumper={false}
                  showSizeChanger={false}
                  pageSize={1}
                  current={
                    Object.values(props.boardList).filter(list =>list.id === props.currentApplicant.list_id)[0]?
                    Object.values(props.boardList).filter(list =>list.id === props.currentApplicant.list_id)[0]
                    .applicants.findIndex(applicant => applicant.id === props.currentApplicant.id)+1:1
                  }
                  onChange={(page) => {
                    handleTableChange(page);
                  }}
                  total={total} 
                  itemRender={(current: any, type: any) =>
                    itemRender(current, type)
                  }
                /></div>
              </div>
              </div>
            </Overlay>
          </>
        }
        closeIcon={null}
        content={
          <>
            <div
              style={{
                margin: 0,
                position: "relative",
                overflow: "hidden",
                width: "calc(100vw - 150px )",
                maxWidth: "calc(100vw - 150px )",
                height: "calc(100vh - 161px )",
                maxHeight: "calc(100vh - 100px )",
                borderRadius:'4px'
              }}
            >
              <div style={{ display: "flex" }} className="h-full">
                <div
                  style={{ width: "40%", height: "calc(100vh - 179px)", }}
                   className="flex flex-col h-full flex-shrink-0  border-l border-b border-t border-r  overflow-y-scroll "
                >{loading?(<div className="flex h-full justify-center items-center"><Spin/></div>):(<CardLinkedDetails />)}
                </div>
                <div
                  style={{
                    width: "80%",
                  }}
                  className=" pr-0.5 pb-2 overflow-hidden flex flex-col h-full border-t">
                  <ActivityTab />
                </div>
               <Read access={props.currentOpening.access}>
                  <div
                    className="flex flex-col h-full flex-shrink-0 border-t border-l border-b overflow-y-auto"
                    style={{ width: "20%",height: "calc(100vh - 179px)" }}
                  >
                    <div className="pb-3 w-full">                     
                        <SideSectionComponent />                     
                    </div>
                  </div>
                  </Read>
              </div>
            </div>
          </>
        }
        footer={null}
        style={{
          overflowY: "hidden",
          width: " 100vw",
          height: "100vh",
          position: "fixed",
          top: "50px",
          left: "10px",
          bottom: "10px",
          right: "0px",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "0px",
          borderRadius:'4px'
        }}
        className="review-modal-content"
      />
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  currentOpening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
  notificationCenter: state.opening.notificationCenter,
  boardList: state.opening.boardList,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initCurrApplicant: (applicant: currentApplicant) =>
    dispatch(initCurrentApplicant(applicant)),
  clearData: () => dispatch(clearCardData()),
  removeCardNotificationDot: (
    payload: notificationServiceEntityDotRemovePayloadType
  ) => dispatch(unread_dot_remover(payload)),
});
export default connect(mapStateToProps, mapPropsToState)(Card);
