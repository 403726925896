import React from "react";
import { connect } from "react-redux";
import { loginUser, searchDynamicQuery } from "../../type/type.d";
import Read from "../../Read";
import { servicesMembership } from "../../redux/reducers/initialState";
import CDBFilters from "../CDB/CDBFilters";
import CDBSearchBox from "../CDB/CDBSearchBox";
import { getServiceAccessOfLoginUser } from "../candidateDB/hook/getLoginUserServiceAccess";
import EditTable from "../candidateDB/EditTable";
import ImportResumeModal from "../candidateDB/ImportResumeModal";
import QuickAddCandidate from "../candidateDB/QuickAddCandidate";

type CandidateDBHeaderProps = {
  servicesMembership: servicesMembership;
  loginUser: loginUser;
  searchQuery: searchDynamicQuery;
};

const CandidateDBHeader = (props: CandidateDBHeaderProps) => {
  let accessForCDB = getServiceAccessOfLoginUser({
    serviceMembers: props.servicesMembership.CDBMember,
    loginUserId: props.loginUser.user.id,
  });


  return (
    <div className="h-12 w-full flex flex-row justify-between items-center">
      <div className="flex flex-row space-x-2">
        <QuickAddCandidate />
      <ImportResumeModal />
        <Read access={accessForCDB} type={true}>
          <EditTable />
        </Read>
      </div>
      <div className="flex flex-row space-x-2 items-center">
        <CDBSearchBox />

        <CDBFilters />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  servicesMembership: state.opening.servicesMembership,
  loginUser: state.opening.loginUser,
  searchQuery: state.opening.searchQuery,
});


export default connect(mapStateToProps)(CandidateDBHeader);
