import React, { useEffect, useState } from "react";
import TableAnt from "../../ant/TableAnt";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import { getUserInbox } from "../../lib/api";
import { MailPreview } from "../../type/type";
import { connect } from "react-redux";
import { getAllMailsPagewise } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { userMails } from "../../redux/reducers/initialState";
import DetailedMailView from "./DetailedMailView";
import { useHistory } from "react-router-dom";
import TacitbaseAvtar from "../../ant/Avtar";
import { Typography } from "antd";
import "./../../styles/component/style.css";
import AttachmentPreviewOfMail from "../Tmail/AttachmentPreview";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { convertToText } from "./MailInboxTable";
const { Text } = Typography;

function extractNameFromEmail(email:string) {
  // Extract the part before the '+' or '@'
  const name = email.split(/[+@]/)[0];
  return name;
}

const renderAttachments = (attachments: any[]) => {
  const maxVisible = 3;
  const visibleAttachments = attachments.slice(0, maxVisible);
  const moreCount = attachments.length - maxVisible;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {visibleAttachments.map((attachment: any, index: number) => (
        <span key={index} className="w-44" style={{ marginRight: "10px" }}>
                  <AttachmentPreviewOfMail attachment={attachment} />
                  </span>
      ))}
      {moreCount > 0 && (
        <span style={{ marginLeft: "8px", color: "gray" }}>
          +{moreCount} more
        </span>
      )}
    </div>
  );
};

interface MailSentTableProps {
  getAllMailsPagewise(
    currentPage: string,
    mail: MailPreview[],
    tab: string,
    total_count: number
  ): void;
  userMails: userMails;
  applicationId: string;
}

function MailSentTable(props: MailSentTableProps) {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  // const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 25,
      position: ["bottomLeft"],
      size: "default",
      showSizeChanger: false,
      style: {
        marginTop: "0px",
        borderTop: "1px solid rgba(5, 5, 5, 0.06)",
        paddingTop: "9px",
      },
    },
  });
  // Column definitions for the Ant Design table
  const columns = [
    {
      width: "21%",
      // title: "Name",
      ellipsis: true,
      dataIndex: ["to"],
      render: (record: { mailto: any; attachments: any ;count:number}) => (
        <div
          className={`flex flex-col ${
            record.attachments.length > 0 ? "-mt-7" : ""
          }`}
          style={{ display: "flex", alignItems: "start", height: "100%",paddingLeft: "2px" }}
        >
          <div
            className="truncate w-full ml-4"
            style={{
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "start",
              height: "100%",
            }}
          >
            <TacitbaseAvtar content={record.mailto[0][0]} />
            <div
            className="flex items-center flex-1" // Allow container to take available space
            style={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden", // Ensure content doesn't overflow the container
              width: "100%", // Full width for this container
              marginLeft:'8px'
            }}
          >
            {/* Truncated name */}
            <div
              className="truncate pr-1"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                // maxWidth:'78%'
              }}
            >
              {capitalizeFirstLetter(extractNameFromEmail(record.mailto[0]))}
            </div>
      
            {/* Count (moves to end if name is too long) */}
            <Text
              type="secondary"
              className="pl-1"
              style={{
                flexShrink: 0, // Prevent count from shrinking
              }}
            >
              {record.count}
            </Text>
          </div>
          </div>
          <div> </div>
        </div>
      ),
    },
    {
      width: "62%",
      // title: "Subject",
      dataIndex: "content",
      ellipsis: true,
      render: (text: { subject: string; body: string; attachments: any }) => {
        return (
          <>
            <span title={text.subject} style={{ paddingLeft: "16px" }}>
            {text.subject ? (
            <>
              {text.subject}
              {text.body && (
                <>
                  {" - "}
                  <Text type="secondary">{convertToText(text.body)}</Text>
                </>
              )}
            </>
          ) : (
            <>
             (No Subject)
              {text.body && (
                <>
                  {" - "}
                  <Text type="secondary">{convertToText(text.body)}</Text>
                </>
              )}
            </>
          )}{" "}
            </span>
            {text.attachments.length > 0 && (
              <div style={{ paddingLeft: "16px" }} className="my-1 mt-1.5">
                {text.attachments.length > 0 &&
                  renderAttachments(text.attachments)}
              </div>
            )}
          </>
        );
      },
    },
    {
      width: "20%",
      // title: "Submitted at",
      dataIndex: "created_at",
      ellipsis: true,
      render: (record: { at: string; attachments: any }) => (
        <div
          style={{ paddingLeft: "16px", whiteSpace: "nowrap",paddingRight: "8px" , textAlign: "right"}}
          className={`${record?.attachments?.length > 0 ? "-mt-7" : ""}`}
        >
          {renderCreatedAt(record.at)}
        </div>
      ),
    },
  ];

  const history = useHistory();

  useEffect(() => {
    setLoading(true); // Set loading to true before fetching data
    getUserInbox(
      "sent",
      tableParams.pagination.current,
      tableParams.pagination.pageSize
    )
      .then((res) => {
        if (res) {
          props.getAllMailsPagewise(
            tableParams.pagination.current.toString(),
            res.items,
            "sent",
            res.total_count
          );
          setTableParams((prev: any) => ({
            ...prev,
            pagination: {
              ...prev.pagination,
              total: res.total_count,
            },
          }));
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after fetching data
      });
  }, [tableParams.pagination.current, tableParams.pagination.pageSize]);

  useEffect(() => {
    // Dynamically set the height of the table body
    const tableBody: any = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.style.height = "calc(100vh - 98.8px)";
    }
  }, []);

  const tableData =
    props?.userMails?.["sent"]?.[tableParams.pagination.current] &&
    Object.values(props?.userMails["sent"][tableParams.pagination.current]);
  // const messagesArray =
  //   (tableData && tableData?.map((item) => item?.message)) || [];

  const generateData = (result: any) => {
    return result?.map((mail: any) => ({
      key: mail.message.id,
      id: mail.message.id,
      to: { mailto: mail.message.to, attachments: mail.message.attachments,count: mail.msg_count },
      attachments: mail.message.attachments,
      body: mail.message.body,
      subject: mail.message.subject,
      message_key: mail.message.key,
      created_at: {
        at: mail.message.created_at,
        attachments: mail.message.attachments,
      },
      read: mail.message.read,
      message_count: {
        count: mail.msg_count,
        attachments: mail.message.attachments,
      },
      content: {
        subject: mail.message.subject,
        body: mail.message.body,
        attachments: mail.message.attachments,
      },
    }));
  };

  // const tableConvertedData1 = generateData(messagesArray);

  const tableConvertedData = generateData(tableData);

  // console.log(tableConvertedData1)

  // // Handle row selection changes
  // const onSelectChange = (selectedRowKeys: React.Key[]) => {
  //   setSelectedRowKeys(selectedRowKeys);
  //   // setSelectedRowKeysData(selectedRows);
  // };

  // Handle table changes
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
  };

  // Handle row click event
  const onRowClick = (record: any) => {
    // setTimeout(()=>{
    history.push(`/o/${props.applicationId}/tmail/sent/${record?.id}`);
    // },1000)
  };

  // Handle row hover event
  const handleRowHover = (record: any) => {
    setHoveredRow(record ? record.key : null);
  };

  return (
    <>
      <div className=""></div>
      <div className="bg-white h-full z-0  pr-0.5">
        <div className="h-full flex w-full">
          <TableAnt
            rowClassName={(record) => {
              let rowClass = "cursor-pointer";

              // Keep 'unread-message' class if the message is unread
              if (record.read === false) {
                rowClass += " unread-message";
              }

              // Add hover class if the row is hovered
              if (record.id === hoveredRow) {
                rowClass += " hovered-row";
              }

              return rowClass;
            }}
            size="small"
            loading={loading}
            columns={columns}
            data={tableConvertedData}
            className="h-full w-full bg-white rounded overflow-y-hidden"
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            scroll={{ y: "calc(100vh - 85.8px)" }}
            // rowSelection={{
            //   selectedRowKeys,
            //   preserveSelectedRowKeys: true,
            //   onChange: onSelectChange,
            //   columnWidth: 45,
            // }}
            onRow={(record) => ({
              onMouseEnter: () => handleRowHover(record),
              onMouseLeave: () => handleRowHover(null),
              onClick: () => onRowClick(record),
            })}
            showHeader={false}
          />
        </div>
        <DetailedMailView pageNumber={tableParams.pagination.current} />
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  userMails: state.opening.userMails,
  applicationId: state.opening.applicationId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  // initMailNotificationDot: (payload:notificationServiceEntityDotChangePayloadType) =>
  // dispatch(initServiceEntityNotificationsDot(payload)),
  getAllMailsPagewise: (
    currentPage: string,
    mail: MailPreview[],
    tab: string,
    total_count: number
  ) => dispatch(getAllMailsPagewise(currentPage, mail, tab, total_count)),
});
export default connect(mapStateToProps, mapPropsToState)(MailSentTable);

