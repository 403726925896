import React, { useEffect } from "react"
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { getOpeningMembers } from "../../../lib/api";
import { initOpeningMembership } from "../../../redux/actions/opening/action";
import { Opening, orgMember, openingMemberType, OpeningMembers, Member } from "../../../type/type"
import { Avatar, Divider } from "antd"
import TacitbaseAvtar from "../../../ant/Avtar"
import { generateTooltipForMember } from "../../../utils/capitalizeFirstLetter";

type BoardMemberProps = {
  currentOpening: Opening;
  openingMembers: OpeningMembers;
  member: orgMember;
  applicationId: string;
  initOpeningMembers(member: any, openingId: string): void;
};

const BoardMember = (props: BoardMemberProps) => {
  let boardUrlParam: any = {};
  boardUrlParam = useParams();

  useEffect(() => {
    getOpeningMembers(boardUrlParam.board_id, props.applicationId).then((members: Member[]) => {
      props.initOpeningMembers(members, boardUrlParam.board_id);
    });
  }, [boardUrlParam.board_id]);

  if (!props.openingMembers[props.currentOpening.id]) return null;

  const creatorId = props.currentOpening.member_id
  const isCreatorActivated = creatorId && props.member[creatorId] && props.member[creatorId].registrations[0].data.activated

  const membersWithoutCreator = props.openingMembers[props.currentOpening.id]
    .filter((memberId: openingMemberType) => memberId.id !== creatorId)
    .map((memberId: openingMemberType) => (
      props.member[memberId.id] && props.member[memberId.id].registrations[0].data.activated &&
      <TacitbaseAvtar
        key={props.member[memberId.id].id}
        toolTip={generateTooltipForMember(props.member[memberId.id])}
        src={props.member[memberId.id].image_url}
        size="default"
        content={props.member[memberId.id].first_name[0] + props.member[memberId.id].last_name[0]}
      />
    ))

  return (
    <div className="flex items-center mr-1" style={{height:30}}>
      {isCreatorActivated && (
        <Avatar.Group maxCount={4} size={"default"}>
          {props.member[creatorId].image_url ?
            <TacitbaseAvtar
              toolTip={generateTooltipForMember(props.member[creatorId])}
              src={props.member[creatorId].image_url}
              size="default"
            /> : <> <TacitbaseAvtar
              toolTip={generateTooltipForMember(props.member[creatorId])}
              content={props.member[creatorId].first_name[0] + props.member[creatorId].last_name[0]}
              size="default"
            /></>}
        </Avatar.Group>
      )}
      {membersWithoutCreator.length > 0 && (
        <>
          <Divider type="vertical" style={{ borderColor: "black" }} />
          <Avatar.Group maxCount={4} size={"default"}>{membersWithoutCreator}</Avatar.Group>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.openingMembers,
  member: state.opening.members,
  currentOpening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initOpeningMembers: (members: any, openingId: string) => {
    dispatch(initOpeningMembership(members, openingId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardMember)
