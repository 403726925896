import React, {  useEffect, useState } from "react";
import { connect } from "react-redux";
import {  Button, Divider, Popconfirm, Tag, Typography } from "antd";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import GetplanModel from "./GetplanModel";
import { downGradePlan, getOrgDetails } from "../../../lib/api";
import { Dispatch } from "redux";
import { current_org, getActiveBoard } from "../../../redux/actions/opening/action";
import { TotalBoards } from "../../../type/type";

const { Text } = Typography;

type billingIndexProps = {
  external_id: string;
  stripeCustomerId: string;
  currentOrg: any;
  UpdateCurrentOrg(orgData: any): void;
  applicationId:string;
  getActiveBoard(data: TotalBoards):void;
};

type BillingDetail = {
  name: string;
  interval: string;
  validity: { start: number; end: number };
  price: number;
  currency: string;
};
const BillingIndex = (props: billingIndexProps) => {
  const [activePlan, setActiveplan] = useState<BillingDetail>();
  const [nextInvoiceDate, setNextInvoiceDate] = useState<string>("");
  const [showModel, setShowModel] = useState(false);
  const currentOrgData = props.currentOrg.customer;

  // getting current active plan on first rendering
  useEffect(() => {
    const billingDetail: BillingDetail = {
      name: currentOrgData.metadata.plan,
      currency: currentOrgData.currency,
      validity: {
        start: currentOrgData.subscriptions?.data[0]?.current_period_start,
        end: currentOrgData.subscriptions?.data[0]?.current_period_end,
      },
      interval:
        currentOrgData.subscriptions?.data[0]?.items.data[0].plan.interval,
      price: currentOrgData.subscriptions?.data[0]?.items.data[0].plan.amount,
    };
    setActiveplan(billingDetail);
    const nextInvoiceTimestamp =
      currentOrgData.subscriptions?.data[0]?.current_period_end + 86400;
    const nextInvoiceDate = formatDate(nextInvoiceTimestamp);
    setNextInvoiceDate(nextInvoiceDate);
  }, [props.currentOrg]);

  const formatDate = (timestamp: any) => {
    if (!timestamp || isNaN(timestamp)) {
      console.error("Invalid timestamp:", timestamp);
      return "Invalid date";
    }

    const date = new Date(Number(timestamp) * 1000);

    if (isNaN(date.getTime())) {
      console.error("Invalid date object:", date);
      return "Invalid date";
    }

    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(date);
  };

  const handleUpgradeClick = () => {
    setShowModel(true);
};
const handleModalCancel = () => {
  setShowModel(false);
};

const handleCancelPlan = ()=>{  
    return new Promise<void>((resolve, reject) => {
      downGradePlan().then((apires)=>{
        getOrgDetails(props.applicationId).then((res:any)=>{
          props.UpdateCurrentOrg(res)
        })
        props.getActiveBoard(apires?.subscriptions?.job_board)
        resolve();
      }).catch((err)=>{
        reject(err);
      })
    })
}
  return (
    <>
      <div className="p-4">
        <Text style={{ fontWeight: "500" }}>Billing settings</Text>
      </div>
      <Divider type="horizontal" className="w-full m-0 " />
      <div
        className="w-full  font-light"
        id="link_1"
      >
        <div className=" flex p-4 flex-col">
          <Text style={{ fontWeight: "500" }}>Subscription details</Text>
          <Text style={{ fontWeight: "400"}}>
            Update your billing details including subscription, account details.
          </Text>
        </div>
        <div className="flex sm:flex-col md:flex-col lg:flex-col xl:flex-row 2xl:flex-row">
          <div
            className="ml-4 my-4 p-4 flex flex-col border rounded-md"
            style={{
              width: "441px",
            }}
          >
            <div className="flex gap-2 flex-col ">
              <div className="flex justify-between">
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                type="secondary"
              >
                Plan details
              </Text>
              </div>
              <div className="flex items-center gap-4 ">
                <Text
                  style={{
                    fontSize: "30px",
                    fontWeight: "500",
                  }}
                >
                  { activePlan?.name && capitalizeFirstLetter(activePlan.name)}
                </Text>
                {activePlan?.interval &&(<Tag
               color="magenta"
                >
                  {activePlan?.interval
                    ? activePlan.interval === "month"
                      ? "Monthly"
                      : activePlan.interval === "year"
                      ? "Yearly"
                      : capitalizeFirstLetter(activePlan?.interval)
                    : ""}
                </Tag>)}
              </div>
              {currentOrgData.subscriptions !== null &&(<div>
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  type="secondary"
                >
                  {`  Valid till: ${formatDate(activePlan?.validity?.end)}`}
                </Text>
              </div>)}
              <Text type="secondary">
              {currentOrgData?.email}
              </Text>
              <div>
                <Divider className="my-2 mx-0" />
                <div className="flex justify-between">
                  {currentOrgData.subscriptions !== null ?(
                    <Popconfirm
                    title="Downgrade to the free plan?"
                    description={
                      <div className="w-100">
                        <Text>Are you sure you want to downgrade your subscription? By downgrading, you will:</Text>
                        <ul className="mt-4">
                          <li>Have your job posts archived, and you will only be able to unarchive up to 3 job posts.</li>
                          <li>Lose access to AI features</li>
                          <li>Be unable to send bulk emails.</li>
                        </ul>
                        <p className="mt-4">Please consider these changes before confirming your downgrade.</p>
                      </div>
                    }
                    okText = {'Downgrade to the free plan'}
                    cancelText = {'Cancel'}
                    onConfirm={handleCancelPlan}
                  >
                    <Button style={{
                      color: "#666666",
                      textDecoration: "underline",
                      padding:0,
                    }} type="link">Downgrade to the free plan</Button>
                  </Popconfirm>
                   
                 ):(<div></div>)}
                  <div className="flex">
                    <Button
                      type="link"
                      onClick={handleUpgradeClick}
                      className=" text-tacitbase-secondary3 "
                      style={{
                        textDecoration: "underline",
                        color:"#4B48A5" ,
                        padding:0,
                      }}
                    >
                      Manage account
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {currentOrgData.subscriptions !== null &&(<div
            className="m-4 p-4 flex-1 border rounded-md"
            style={{
              minWidth: "266px",
              maxWidth:"200px",
              height: "188px",
            }}
          >
            <div className="flex gap-2 flex-col">
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                type="secondary"
              >
                Last invoice
              </Text>
                <Text
                  style={{
                    fontSize: "30px",
                    fontWeight: "500",
                  }}
                >
                 {`$ ${activePlan ? (activePlan.price / 100).toFixed(2) : ""}`}
                </Text>
            <div>
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                }}
                type="secondary"
              >
                {`${formatDate(activePlan?.validity?.start)}`}
              </Text>
            </div>
            <div>
              <Divider className="my-2 mx-0" />
              <div className="flex">
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Next invoice : 
                </Text>
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  type="secondary"
                >
                  &nbsp;{nextInvoiceDate}
                </Text>
              </div>
            </div>
            </div>
          </div>)}
        </div>
      </div>
      <GetplanModel
      isModalVisible={showModel}
      onCancel={handleModalCancel}
      />
    </>
  );
};
const mapPropsToState = (dispatch: Dispatch) => ({
  UpdateCurrentOrg: (orgData: any) => dispatch(current_org(orgData)),
  getActiveBoard:(data: TotalBoards)=> dispatch(getActiveBoard(data)),
})

const mapStateToProps = (state: any) => ({
  external_id: state.opening.applicationId,
  stripeCustomerId: state.opening.currentOrg?.customer?.id,
  currentOrg: state.opening.currentOrg,
  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps,mapPropsToState)(BillingIndex);
