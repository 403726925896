import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { currentApplicant, Opening } from "../../type/type";
import { Divider, Input, List, Tabs, Typography } from "antd";
import CardMember from "./cardMember/CardMember";
import CardPreviewLabel from "./cardLabel/CardLabel";
import {
  capitalizeFirstLetter,
  formatLink,
} from "../../utils/capitalizeFirstLetter";
import Attachment from "./attachement/Attachment";
import CardDescription from "./cardDescription/CardDescription";
import Link from "antd/es/typography/Link";
const { Text } = Typography;

// import { ExportOutlined } from "@ant-design/icons";
// import Link from "antd/es/typography/Link";
import { predefinedFields, SOCKET_CLIENT_ID, TACITBASE } from "../../constant";
import { CheckOutlined, ExportOutlined, SyncOutlined } from "@ant-design/icons";
import { editApplicantName } from "../../lib/api";
import { Dispatch } from "redux";
import { editName } from "../../redux/actions/opening/action";
import { throwSuccess } from "../../service/throwError";

type CardLinkedDetailsProps = {
  currentApplicant: currentApplicant;
  updateApplicantName(nameObj: any): void;
  currentOpening: Opening;
};
const CardLinkedDetails = (props: CardLinkedDetailsProps) => {
  const [data, setData] = useState<any[]>();
  const [cardName, setCardName] = useState(props.currentApplicant.name);
  const [saveStatus, setSaveStatus] = useState<string>("");
  const [editingKey, setEditingKey] = useState<string | null>(null);


  useEffect(() => {
    setCardName(props.currentApplicant.name);
  }, [props.currentApplicant.name]);

  const editCardName = (e: any) => {
    if (
      (e.keyCode === 13 && !e.shiftKey) ||
      e.button === 0 ||
      e.type === "blur"
    ) {
      e.preventDefault();
      if(e.target.value === props.currentApplicant.name){
        setEditingKey("")
        setSaveStatus("")
        return
      }
      const name = cardName.trim();

      const req = {
        id: props.currentApplicant.id,
        name: name,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
      };

      if (name !== "" && name !== props.currentApplicant.name) {
        setSaveStatus("saving"); 
        editApplicantName(req).then((applicant: any) => {
          document.title = `${applicant.name
            .charAt(0)
            .toUpperCase()}${applicant.name.slice(
            1
          )} on ${props.currentOpening.name
            .charAt(0)
            .toUpperCase()}${props.currentOpening.name.slice(
            1
          )} | ${TACITBASE}`;

          props.updateApplicantName(applicant);
          setCardName(applicant.name);
          setEditingKey("")
          setTimeout(() => {
            setSaveStatus("saved"); // Show saved status
            throwSuccess("Card updated successfully.");
          }, 1000);
          setTimeout(() => setSaveStatus(""), 2000);
        }).catch(()=>{
          setSaveStatus("");
        });
      } else {
        setCardName(props.currentApplicant.name);
      }
    }
  };

  const CandidatesdetailedFields = [
    { name: "skills", type: "paragraph", view: "Skills", custom: false },
    { name: "location", type: "paragraph", view: "Location" },
    {
      name: "highest_education",
      type: "paragraph",
      view: "Highest education",
      custom: false,
    },
    {
      name: "latest_experience",
      type: "paragraph",
      view: "Latest experience",
      custom: false,
    },
    {
      name: "notice_period_in_days",
      type: "number",
      view: "Notice period (Days)",
      custom: false,
    },
  ];
  useEffect(() => {
    if (props.currentApplicant.source) {
      setData(Object.entries(props.currentApplicant.source));
    }
  }, [props.currentApplicant]);
  const renderEditableField = (fieldName: any) => {
    let field = data?.find(([key]) => key === fieldName);
    if (!field) return null;
    return (
      <div className="truncate relative">
        <div
          style={{
            boxSizing: "border-box",
            margin: 0,
            padding: "5px 12px",
            fontSize: "14px",
            lineHeight: "1.5714285714285714",
            fontFamily: "Inter",
            display: "inline-block",
            width: "100%",
            minWidth: 0,
            borderRadius: "4px",
            height: 32,
            transition: "all 0.2s",
          }}
        >
          {field[1]?field[1]:"Not available"}
        </div>
      </div>
    );
  };

  const items = [
    {
      key: "1",
      label: "Candidate details",
      children: (
        <div style={{ height: "70%", overflow: "auto" }}>
          <List
            itemLayout="horizontal"
            dataSource={CandidatesdetailedFields}
            renderItem={(item) => {
              return (
                <List.Item style={{ padding: "8px 0px 0px 0px" }}>
                  <List.Item.Meta
                    title={item.view && capitalizeFirstLetter(item.name)}
                    description={renderEditableField(item.name)}
                  />
                </List.Item>
              );
            }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Description",
      children: (
        <>
          <div
            className="editor-description border rounded mb-2"
            style={{
              height: "calc(-200px + 67vh)",
            }}
          >
            <CardDescription />
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: "Social profiles",
      children: (
        <List
          itemLayout="horizontal"
          dataSource={predefinedFields}
          style={{ overflowY: "scroll" }}
          renderItem={(item) => {
            const Url = data?.find(([key]) => key === item.name);
            return (
              <List.Item style={{ padding: "8px 0px 0px 0px" }}>
                <List.Item.Meta
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {capitalizeFirstLetter(item.view)}
                      {item.type === "url" && (
                        <span
                          tabIndex={0}
                          role="button"
                          onKeyDown={() => {}}
                          style={{ marginLeft: "8px", cursor: "pointer" }}
                        >
                         {Url?.[1] && Url?.[1] !== "" &&( <Link href={formatLink(Url[1])} target="_blank">
                            <ExportOutlined />
                          </Link>)}
                        </span>
                      )}
                    </div>
                  }
                  description={renderEditableField(item.name)}
                />
              </List.Item>
            );
          }}
        />
      ),
    },
  ];
  return (
    <div className="w-full">{
      saveStatus && (
      <div
        style={{
          position: "fixed",
          top: 56,
          left: 220,
          width: "100%",
          padding: "10px",

          color: "black",
          textAlign: "left",
          zIndex: 1000,
        }}
      >
        {saveStatus === "saving" ? (
          <span className=" w-full font-bold" style={{ color: "#009D79"}}>
            <SyncOutlined
              spin={true}
              style={{ color: "#009D79", marginRight: 4 ,}}
            />{" "}
            Saving changes...
          </span>
        ) : (
          <span style={{ color: "#009D79" }} className="font-bold ">
            <CheckOutlined
              size={100}
              style={{ color: "#009D79", marginRight: 4 }}
              type="success"
            />
            Changes saved
          </span>
        )}
      </div>
    )}
    <div className="w-full max-w-full">
      <div className="flex flex-row px-4 h-10 isEditable items-center justify-between w-full">
      {editingKey === "name" ? (
          <div>
            <Input
              value={cardName}
              type="text"
              onChange={(e) => {
                setCardName(e.target.value);
              }}
              onKeyDown={(e) => editCardName(e)}
              onBlur={(e) => editCardName(e)}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
          </div>
        ) : (
          <div
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => setEditingKey("name")}
            style={{}}
            className="truncate w-full relative"
          >
            <div
              style={{
                boxSizing: "border-box",
                margin: 0,
                padding: "5px",
                fontSize: "14px",
                lineHeight: "1.5714285714285714",
                fontFamily: "Inter",
                display: "inline-block",
                width: "100%",
                minWidth: 0,
                borderRadius: "4px",
                height: 32,
                transition: "all 0.2s",
              }}
            >
              <Text ellipsis={{tooltip:cardName}} style={{maxWidth:"70%"}} strong className="text-xl">{cardName}</Text>
            </div>

          </div>
        )}
      </div>
    <Divider type="horizontal" className="mt-1 mx-0 mb-0" />
      <div className="flex flex-col gap-3">
        <CardMember />
        <CardPreviewLabel />
        <Attachment />
      </div>
      {props.currentApplicant.candidate_id && props.currentApplicant.source ? (
        <div className="mb-0 px-4">
          <div
            className="w-full"
            style={{ maxHeight: "calc(50%-20px)", paddingRight: "7px" }}
          >
            <Tabs
              defaultActiveKey="1"
              items={items}
              tabPosition="top"
              style={{ width: "100%" }}
              tabBarStyle={{
                top: 0,
                background: "white",
                marginBottom: "4px",
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  currentApplicant: state.opening.currentApplicant,
  members: state.opening.members,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateApplicantName: (nameObj: any) => dispatch(editName(nameObj)),
});

export default connect(mapStateToProps, mapPropsToState)(CardLinkedDetails);
