import React, { useState } from "react";
import { Menu, Layout, ConfigProvider, Badge } from "antd";
import MailInboxTable from "./MailInboxTable";
import { MailOutlined, SendOutlined } from "@ant-design/icons";
import MailSentTable from "./MailSentTable";
import { connect } from "react-redux";
import { userMails } from "../../redux/reducers/initialState";
// import MailSubHeader from "./MailSubHeader";
import { useHistory } from "react-router-dom";

const { Sider, Content } = Layout;

interface MailSideBarProps{
  userMails: userMails;
  applicationId: string;

}

const MailSideBar = (props:MailSideBarProps) => {
  const history = useHistory();

  // State to keep track of the currently active tab key
  const [activeKey, setActiveKey] = useState("inbox");

  // Handler for menu item click to update the active tab key
  const handleMenuClick = (e: any) => {
    setActiveKey(e.key);
    history.push(`/o/${props.applicationId}/tmail/${e.key}`);

  };

  // Configuration for tabs and their respective sub-tabs
  const tabsConfig = [
    {
        label: (
            <span className="flex justify-between items-center">
              <span>
              <MailOutlined /> Inbox
              </span>
              <span>
              <Badge count={props?.userMails?.["inbox"]?.total_count} overflowCount={99999} style={{backgroundColor:'#4B48A5'}}/>
            </span>
            </span>
          ),
      key: "inbox",
      path:'/inbox',
      Content: <MailInboxTable/>
    },
    {
        label: (
            <span>
              <SendOutlined /> Sent
            </span>
          ),
      key: "sent",
      path:'/sent',
      Content: <MailSentTable/>
    },
  ];

   // Function to render the content of the active tab
  const renderContent = () => {
    const activeTab = tabsConfig.find((tab) => tab.key === activeKey);
    return activeTab ? activeTab.Content : null;
  };
  

  return (
    <div className="h-full flex flex-col">
        <div className="">
          <div className="bg-white">
          {/* <MailSubHeader/> */}
            <Layout style={{ borderRadius: 4, height: "calc(-50px + 100vh)" }}>
              <Sider
                className="site-layout-background border-r h-full"
                width={ 200}
                collapsible
                theme="light"
                trigger={null}
              >
            <ConfigProvider
              theme={{
                components: {
                  Menu: {
                    colorText: "black",
                    colorBgContainer: undefined,
                    itemBg: "white",
                    /* here is your component tokens */
                  },
                },
              }}
            >
               <Menu
                  mode="inline"
                  className="rounded-bl rounded-tl h-full"
                  defaultSelectedKeys={["inbox"]}
                  style={{ height: "100%", borderRight: 0 }}
                  onClick={handleMenuClick}
                >
                  {tabsConfig.map((tab) => (
                    <Menu.Item key={tab.key}>{tab.label}</Menu.Item>
                  ))}
                </Menu>
            </ConfigProvider>
          </Sider>
            <Content className="h-full bg-white rounded">{renderContent()}</Content>
        </Layout>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userMails: state.opening.userMails,
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps)(MailSideBar);
