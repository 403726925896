import { Button, Divider, Empty, Popover, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  Attachment,
  currentApplicant,
  Member,
  orgMember,
  RecordThreads,
} from "../../../type/type";
import { EMAIL_TAG, TACITMAIL } from "../../../constant";
import { connect } from "react-redux";
import TacitbaseAvtar from "../../../ant/Avtar";
import { renderCreatedAt } from "../../../utils/renderCreatedAt";
import parse from "html-react-parser";
import { getRecordMailPreview, getUserThreadOfMessage } from "../../../lib/api";
import AttachmentPreviewOfMail from "../../Tmail/AttachmentPreview";
import { seperateMailAddress } from "../../../utils/seperateMailAddress";
import Tacitmail from "../../../assets/Tacitmail";
import {
  getSelectedMailThread,
  initComments,
} from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import ShowMailDetailsPopup from "../../Tmail/ShowMailDetailsPopup";
import { DownOutlined } from "@ant-design/icons";

const { Text } = Typography;

type CardMailProps = {
  comments: any;
  currentApplicant: currentApplicant;
  applicationId: string;
  members: orgMember;
  initComments(comments: any, tag: string, applicant_id: string): void;
  getSelectedMailThread: (
    currentMailId: string,
    threadMsg: RecordThreads
  ) => void;
};

const CardDetailMail = (props: CardMailProps) => {
  const [currentApplicantMail, setCurrentApplicantMail] = useState<any[]>([]);
  const [currentMailId, setCurrentMailId] = useState<string>("");
  const [currentMailThread, setCurrentMailThread] = useState<any[]>([]);
  const [showAllThreads, setShowAllThreads] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
  getRecordMailPreview(props.currentApplicant.id).then((res) => {
      props.initComments(res.items, EMAIL_TAG, props.currentApplicant.id);
    });
    getUserThreadOfMessage(props.currentApplicant.id).then((res) => {
      props.getSelectedMailThread(props.currentApplicant.id, res);
    });
  },[])
  useEffect(() => {
    if (props.comments[props.currentApplicant.id]) {
      const filteredMails = Object.values(
        props.comments[props.currentApplicant.id]?.candidateEmails
      )
        ?.filter((mail: any) => mail.tag === EMAIL_TAG)
        ?.sort((a: any, b: any) => {
          const dateA = new Date(a.message.created_at);
          const dateB = new Date(b.message.created_at);

          return dateB.getTime() - dateA.getTime();
        });
      setCurrentApplicantMail(filteredMails);
    }
  }, [props.currentApplicant, props.comments]);

  useEffect(() => {
    if (currentMailId && showAllThreads) {
      setLoading(true);
      getUserThreadOfMessage(currentMailId)
        .then((res: any) => {
          setCurrentMailThread(res.messages);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCurrentMailThread([]);
    }
  }, [currentMailId, showAllThreads]);

  const handleToggleThread = (id: string) => {
    if (currentMailId === id) {
      setShowAllThreads(!showAllThreads);
    } else {
      setCurrentMailId(id);
      setShowAllThreads(true);
    }
  };


  if (currentApplicantMail.length === 0) {
    return (
      <Empty
        description="No mail found"
        className="flex flex-col h-full justify-center items-center"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  }
  return (
    <div className="p-2 h-full overflow-y-scroll">
      <div className="flex flex-col gap-3">
        {currentApplicantMail.map((mail: any) => {
          const isCurrentMailSelected =
            currentMailId === mail.message.id && showAllThreads;
          let address = seperateMailAddress(mail.message.from);
          let emailExists = Object.values(props.members).find(
            (member: Member) => member.id === mail.message.user_id
          );

          return (
            <div key={mail.message.id}>
              {!isCurrentMailSelected ? (
                <div
                  style={{
                    boxShadow:
                      "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                  }}
                  className="flex flex-col rounded shadow-lg gap-2 p-2 w-full cursor-pointer"
                >
                  <div className="flex gap-2">
                    <div className="h-7 w-7">
                      {emailExists ? (
                        <TacitbaseAvtar
                          src={emailExists.image_url}
                          key={emailExists.id}
                          size="default"
                        />
                      ) : address.name == TACITMAIL ? (
                        <Tacitmail />
                      ) : (
                        <TacitbaseAvtar
                          size="default"
                          content={address.name[0]}
                        />
                      )}
                    </div>
                    <div className="flex w-full flex-col">
                      <div className="flex justify-between gap-2">
                        <Text
                          strong
                          className="truncate-from"
                          style={{
                            maxWidth: "67%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {seperateMailAddress(mail.message.from)?.name}
                        </Text>
                        <div className="text-xs items-center flex space-x-2">
                          <div className="flex text-xs leading-none">
                            <Text
                              type="secondary"
                              className="text-sm"
                              style={{ fontSize: "13px" }}
                            >
                              <div>
                                {renderCreatedAt(mail.message.created_at)}
                              </div>
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                      <Text
                      ellipsis
                      style={{maxWidth:"70%"}}
                       type="secondary"
                       >To: {mail.message.to[0]}</Text>
                      <Popover
                        content={<ShowMailDetailsPopup mail={mail.message} />}
                        placement="bottomLeft"
                        trigger="click"
                      >
                        <Button
                          icon={
                            <DownOutlined
                              style={{ color: "#8c8c8c", fontSize: "12px" }}
                            />
                          }
                          type="text"
                          className="m-0 flex p-0"
                          size="small"
                          style={{ height: "16px", width: "16px" }}
                        />
                      </Popover>
                      </div>
                    </div>
                  </div>
                  <Divider type="horizontal" className="my-2" />
                  <Text strong>{mail.message.subject}</Text>
                  <Text>{parse(mail.message.body)}</Text>
                  <div className="flex flex-wrap">
                    {mail.message.attachments.length > 0 &&
                      mail.message.attachments.map((attachment: Attachment) => (
                        <div
                          key={attachment.id}
                          className="text-xs mb-1 mr-1 flex space-x-2 w-40"
                        >
                          <AttachmentPreviewOfMail attachment={attachment} />
                        </div>
                      ))}
                  </div>
                  {mail.msg_count > 1 && (
                    <div className="flex items-center justify-center my-2">
                      <div className="flex-col">
                        <Divider className="my-1 w-3" />
                        <Divider className="m-0 w-3" />
                      </div>
                      <div
                        onClick={() => handleToggleThread(mail.message.id)}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                        className="h-8 w-8 border rounded-full flex items-center justify-center cursor-pointer"
                      >
                        {mail.msg_count - 1}
                      </div>
                      <div className="flex-col w-full">
                        <Divider className="my-1 w-3" />
                        <Divider className="m-0 w-3" />
                      </div>
                    </div>
                  )}
                </div>
              ) : loading ? (
                <div className="flex items-center justify-center h-full w-full">
                  <Spin />
                </div>
              ) : (
                <div className="mt-2">
                  <Divider>Thread Messages</Divider>
                  {currentMailThread.map((thread: any) => (
                    <div
                      key={thread.id}
                      className="p-2 flex gap-2 flex-col border-b"
                    >
                      <div className="flex gap-2">
                        <TacitbaseAvtar
                            size="default"
                            content={thread.from[0]}
                          />
                        <div className="flex flex-col gap-2">
                      <div className="flex w-full justify-between">
                         <div className="flex items-center gap-2">
                          <Text
                            strong
                          >
                            {seperateMailAddress(thread.from)?.name }
                          </Text>
                        </div>
                        <div className="text-xs items-center flex space-x-2">
                          <div className="flex text-xs leading-none">
                            <Text
                              type="secondary"
                              className="text-sm"
                              style={{ fontSize: "13px" }}
                            >
                              <div>{renderCreatedAt(thread.created_at)}</div>
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-2 items-center">
                      <Text
                      ellipsis
                      style={{maxWidth:"70%"}}
                       type="secondary">To: {thread.to[0]}</Text>
                      <Popover
                        content={<ShowMailDetailsPopup mail={thread} />}
                        placement="bottomLeft"
                        trigger="click"
                      >
                        <Button
                          icon={
                            <DownOutlined
                              style={{ color: "#8c8c8c", fontSize: "12px" }}
                            />
                          }
                          type="text"
                          className="m-0 flex p-0"
                          size="small"
                          style={{ height: "16px", width: "16px" }}
                        />
                      </Popover>
                      </div>
                      </div>
                      </div>                    
                      <Text strong>{thread.subject}</Text>
                      <Text>{parse(thread.body)}</Text>
                      <div className="flex flex-wrap">
                        {thread.attachments.length > 0 &&
                          thread.attachments.map((attachment: Attachment) => (
                            <div
                              key={attachment.id}
                              className="text-xs mb-1 mr-1 flex space-x-2 w-40"
                            >
                              <AttachmentPreviewOfMail
                                attachment={attachment}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-center my-2">
                    <Button
                      type="link"
                      onClick={() => handleToggleThread(mail.message.id)}
                    >
                      Close Thread View
                    </Button>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  initComments: (comments: any, tag: string, applicant_id: string) =>
    dispatch(initComments(comments, tag, applicant_id)),
  getSelectedMailThread: (currentMailId: string, threadMsg: RecordThreads) =>
    dispatch(getSelectedMailThread(currentMailId, threadMsg)),
});

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  comments: state.opening.comments,
  applicationId: state.opening.applicationId,
  members: state.opening.members,
});

export default connect(mapStateToProps, mapPropsToState)(CardDetailMail);
