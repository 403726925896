import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { changeStateOfCloseReview, listOfBoards } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import { CandidateReview, CandidatesInReview, companyBoards, List, Opening, OrganizedBoard } from "../../type/type"
import { CreateCandidateCard, getOpeningLists, getOpenings } from "../../lib/api"
import { Divider, Select, Typography } from "antd"
import { LabeledIconButton } from "../../ant/Button"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { Popover, Space } from "antd/lib"
import { CloseOutlined } from "@ant-design/icons"
import Paragraph from "antd/es/typography/Paragraph"
import { throwSuccess } from "../../service/throwError"
const { Text } = Typography
const SendReviewToBoard = (props: { openings: OrganizedBoard, candidatesInReview: CandidatesInReview, companyBoard: companyBoards[], listOfBoards(boardData: Opening[]): void, currentCandidateInReview: CandidateReview }) => {
    const [selectedWorkspace, setSelectedWorkspace] = useState<string | undefined>(undefined)
    const [selectedBoard, setSelectedBoard] = useState<string>("")
    const [boardLists, setBoardLists] = useState<List[]>([])
    const [open, setOpen] = useState(false)
    const [error, setError] = useState<boolean>(false)
    const [loader, showLoader] = useState<boolean>(false)

    useEffect(() => {
        if (open)
            getOpenings()
                .then((openings: Opening[]) => {
                    props.listOfBoards(openings)

                })
        else {
            setSelectedBoard("")
            setSelectedWorkspace("")
        }
    }, [open])
    useEffect(() => {
        if (selectedBoard) {
            getOpeningLists(selectedBoard).then((res: List[]) => {
                if (res.length == 0) {
                    setError(true)
                } else {
                    setError(false)

                    setBoardLists(res)
                }
            })
        }
    }, [selectedBoard])

    const handleWorkspaceChange = (value: string) => {
        setSelectedWorkspace(value)
    }

    const handleBoardChange = (value: string) => {
        setSelectedBoard(value)
    }
    const createReviews = () => {
        showLoader(true)
        const req = {
            candidates: [props.candidatesInReview.reviews[props.currentCandidateInReview.id].candidates.id],
            opening_id: selectedBoard,
        }

        CreateCandidateCard(req).then((res) => {
            if (res) {
                showLoader(false)
                if (req.candidates.length>1) {
                    throwSuccess(`Candidates have been forwarded to the tracker ${props.openings.pageSection.openings[selectedBoard].name}.`)
                } else {
                    throwSuccess(`Candidate have been forwarded to the tracker ${props.openings.pageSection.openings[selectedBoard].name}.`)
                }   
                hide();
            }
        }).catch(() => {
            showLoader(false)
        })

    }
    const hide = () => {
        setOpen(false)
    }
    return (

        <Popover
            open={open}
            title={<div className="flex w-full bg-white justify-between items-center">
                <div>
                </div>
                <Text>{capitalizeFirstLetter('Send to tracker')}</Text>
                <LabeledIconButton
                    type="text"
                    label=""
                    onClick={hide}
                    icon={<CloseOutlined />}
                />
            </div>}
            content={
                <div className="w-72">
                    <Divider className="my-2" />
                    <Space direction="vertical">

                        <div >
                            <Text>{"Select workspace"}</Text>
                            <div className="w-full">
                                <Select
                                    placeholder="Select workspace"
                                    value={selectedWorkspace}
                                    onChange={handleWorkspaceChange}
                                    onMouseDown={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                    onKeyDown={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                    className="w-full"
                                >
                                    {Object.values(props.companyBoard).map(workspace => (
                                        <Select.Option key={workspace.company_id} value={workspace.company_id}>
                                            {workspace.company_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="w-full">

                            <Text>{"Select tracker"}</Text>
                            <div>
                                <Select
                                    placeholder="Select tracker"
                                    value={selectedBoard}
                                    onChange={handleBoardChange}
                                    className="w-72"

                                >
                                    {/* Render board options based on selected workspace */}
                                    {selectedWorkspace &&
                                        Object.values(props.companyBoard)
                                            .find(workspace => workspace.company_id === selectedWorkspace)
                                            ?.boards.map(board => (
                                                <Select.Option key={props.openings.pageSection.openings[board].id} value={props.openings.pageSection.openings[board].id}>
                                                    {props.openings.pageSection.openings[board].name}
                                                </Select.Option>
                                            ))}
                                </Select>
                            </div>
                        </div>
                        {selectedBoard && boardLists ? (
                            <>

                                <Paragraph style={{ fontSize: "13px" }}>
                                    {capitalizeFirstLetter(
                                        `candidates card will be added in the initial list in job tracker`
                                    )}{" "}
                                    <Text strong style={{ fontSize: "13px" }}>
                                        {props.openings.pageSection.openings[selectedBoard].name}
                                    </Text>.
                                    <br />
                                    <Text style={{ fontSize: "13px" }}>
                                        {capitalizeFirstLetter("with all their details, attachments and labels automatically")}
                                    </Text>
                                </Paragraph>
                            </>
                        ) : null}
                        <div>
                            {error && <div style={{ color: "red" }}>List is empty</div>
                            }

                        </div>
                        <div className="justify-end flex">
                            <LabeledIconButton loading={loader} label="Send" type="primary" onClick={createReviews} />
                        </div>
                    </Space >

                </div >
            }
            onOpenChange={(open) => setOpen(open)}
            trigger={"click"}
            placement="bottom"
            className="w-full"
        >
            <LabeledIconButton label="Send to tracker" onClick={() => { }} />
        </Popover >
    )
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    openings: state.opening.openings,
    candidatesInReview: state.opening.candidatesInReview,
    currentCandidateInReview: state.opening.currentCandidateInReview,
    companyBoard: state.opening.companyBoards,
})
const mapPropsToState = (dispatch: Dispatch) => ({
    changeStateOfCloseReview: (reviewId: string) => {
        dispatch(changeStateOfCloseReview(reviewId))
    },
    listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),

})
export default connect(mapStateToProps, mapPropsToState)(SendReviewToBoard)
