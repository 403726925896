import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Integration, newOrganisation } from "../../../../lib/apiRes";
import {
  deleteOrgIntegration,
} from "../../../../lib/api";
import slackImg from "../../../../images/slack.png";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { Dispatch } from "redux";
import {
  addChatIntegration,
  updateChatIntegration,
} from "../../../../redux/actions/opening/action";
import { Alert, Divider, Popconfirm, Typography } from "antd";
import { throwSuccess } from "../../../../service/throwError";
import DeleteIcon from "../../../../assets/DeleteIcon";
import { SLACK_TOKEN_EXPIRE_WARNING } from "../../../../constant";
import { SecondaryButton } from "../../../../ant/Button";
const { Text } = Typography;
type slackCommunicationIntegrationType = {
  initCurrentOrg: newOrganisation;
  integrations: Integration[];
};
const SlackCommunicationIntegration = (
  props: slackCommunicationIntegrationType
) => {
  const [integrations, setIntegration] = useState<Integration[]>([]);
  // State to hold whether Slack is integrated
  const [isSlackIntegrated, setIsSlackIntegrated] = useState(false);
  // State to hold Slack team name
  const [slackTeamName, setSlackTeamName] = useState("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [slackIntegrationMessage, setSlackIntegrationMessage] = useState("");

  useEffect(() => {
    // Check if the integrations array contains a Slack integration
    const slackIntegration = integrations?.find(
      (integration) => integration.app_name === "slack"
    );
    if (slackIntegration) {
      if (slackIntegration.is_valid_integration) {
        setIsSlackIntegrated(true); // Set Slack integration state to true
        setSlackTeamName(slackIntegration.team_name); // Set Slack team name
        setSlackIntegrationMessage("");
      } else {
        setIsSlackIntegrated(false); // Set Slack integration state to false
        setSlackTeamName(""); // Clear Slack team name
        setSlackIntegrationMessage(SLACK_TOKEN_EXPIRE_WARNING);
      }
    } else {
      setIsSlackIntegrated(false); // Set Slack integration state to false
      setSlackTeamName(""); // Clear Slack team name
      setSlackIntegrationMessage("");
    }
  }, [integrations]); // Trigger effect when integrations array changes

  useEffect(() => {
    setIntegration(props.integrations);
  }, [props.integrations]);
  const handleDeleteIntegration = () => {
    return new Promise<void>((resolve, reject) => {
      deleteOrgIntegration("slack").then(() => {
        // If integration is deleted successfully, update state accordingly
        setIntegration(
          integrations.filter((integration) => integration.app_name !== "slack")
        );
        setSlackIntegrationMessage("");
        setIsSlackIntegrated(false);
        throwSuccess("Your Slack workspace disconnected for your organization.");
        setSlackTeamName("");
        setShowDeleteConfirm(false);
        resolve();
      }).catch((err)=>{
        reject(err);
      });
    })
  };

  return (
    <div className="p-4">
      <Divider type="horizontal" className="w-full m-0 " />
      <div className="flex  pt-4 flex-col">
        <Text strong>Third party configuration</Text>
        <Text>
          Connect your communication app with Tacitbase and review candidates
          with just one click.
        </Text>
      </div>
      <div className="flex my-2 justify-end">
        <div
          className="font-normal rounded text-sm"
          style={{ backgroundColor: "#F0EFFE", color: "#4B48A5" }}
        >
          <>
            {isSlackIntegrated ? (
              <div className="px-2 py-1 h-9  rounded w-full border-none  cursor-pointer flex flex-row items-center justify-start space-x-2 " style={{color: "#4B48A5" }}>
                <div className="">
                  <img alt="" width={30} src={slackImg} />
                </div>
                <div
                  className="justify-between flex flex-row items-center w-full truncate"
                >
                  Connected {slackTeamName}
                  <div>
                    <Popconfirm
                      style={{
                        padding: "10px",
                        height: "100px",
                        width: "200px",
                      }}
                      className=" flex flex-col p-2"
                      title = {`Remove  ${slackTeamName} slack app`}
                      description={
                        <>
                        <div className="my-1">
                          <Text
                          >
                            Are you sure you want to remove {slackTeamName}?{" "}
                          </Text>
                          </div>
                          <p
                          >
                            This will revoke all permissions authorizations and
                            for this app,
                          </p>
                          <p>
                            including those granted by other people in your
                            workspace.
                          </p>
                        </>
                      }   
                      onConfirm={handleDeleteIntegration}
                      okText="Confirm"
                      visible={showDeleteConfirm}
                      showCancel={false}
                    >
                      <button onClick={() => setShowDeleteConfirm(true)}>
                        <DeleteIcon />
                      </button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            ) : (
              <SecondaryButton
                type="link"
                onClick={()=>{}}
                style={{
                  border:"1px solid #d9d9d9",
                  color: "#4B48A5"
                }}
                target="_self"
                href={`https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_APP_CLIENT_ID}&scope=${process.env.REACT_APP_SLACK_APP_SCOPE}&user_scope=&state=${props.initCurrentOrg?.application?.id}`}
              >
                <div className="">
                  <img alt="" width={20} src={slackImg} />
                </div>
                  {capitalizeFirstLetter("Connect your Slack")}
              </SecondaryButton>
            )}
          </>
        </div>
      </div>
      {slackIntegrationMessage.length > 0 && (
        <Alert type="warning" message={slackIntegrationMessage} showIcon />
      )}
    </div>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  addIntegrations: (integration: Integration[]) =>
    dispatch(addChatIntegration(integration)),
  updateIntegrations: (integration: Integration) =>
    dispatch(updateChatIntegration(integration)),
});
const mapStateToProps = (state: any) => ({
  initCurrentOrg: state.opening?.currentOrg,
  integrations: state.opening?.chatsIntegration,
});

export default connect(
  mapStateToProps,
  mapPropsToState
)(SlackCommunicationIntegration);
