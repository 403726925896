import { Avatar, Breadcrumb } from "antd";
import React, { useState } from "react";
import {
  ProjectOutlined,
  DatabaseOutlined,
  BulbOutlined,
  FileDoneOutlined,
  SolutionOutlined,
  MailOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import parse from "html-react-parser";

import moment from "moment";
import { TIME_FORMATE } from "../../constant";
import AttachmentIcon from "../../assets/AttachmentIcon";
import CommentNewIcon from "../../assets/CommentNewIcon";
import AttachmentPreview from "../cardActions/addAttachment/AttachmentPreview";
import Link from "antd/es/typography/Link";
import { setUserLastVisitedTime } from "../../lib/api";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
type notificationEventHandlerProps = {
  applicationId: string;
  item: any;
};
const NotificationEventHandler = (props: notificationEventHandlerProps) => {
  const [preview, showPreview] = useState({
    show: false,
    presignedLink: "",
    fileName: "",
    objectKey: "",
  });
  const closePreview = () => {
    showPreview({
      show: !preview.show,
      presignedLink: "",
      fileName: "",
      objectKey: "",
    });
  };
  const SetLastVisitedTime = () => {
    setUserLastVisitedTime(new Date().getTime()).then(() => {});
  };

  const linkGenerators: any = {
    card: (entity: any) => (
      <Link
        style={{ textDecoration: "" }}
        onClick={() => SetLastVisitedTime()}
        href={`b/${props.item.data.deltas.data?.board?.id}/tracker/${entity.id}`}
      >
        {entity.text}&nbsp;
      </Link>
    ),
    board: (entity: any) => (
      <Link href={`b/${entity.id}/tracker`} onClick={() => SetLastVisitedTime()}>
        {entity.text}&nbsp;
      </Link>
    ),
    organisation: (entity: any) => (
      <Link href={`settings/invitations`} onClick={() => SetLastVisitedTime()}>
        {entity.text}&nbsp;
      </Link>
    ),
    candidate: (entity: any) => (
      <Link
        style={{ textDecoration: "" }}
        onClick={() => SetLastVisitedTime()}
        href={`b/${props.item.data.deltas.data?.job_post?.board_id}/pool/${entity.id}`}
      >
        {capitalizeFirstLetter(entity.text)}&nbsp;
      </Link>
    ),
    job: (entity: any) => (
      <Link href={`b/${props.item.data.deltas.data?.job_post?.board_id}/tracker`} onClick={() => SetLastVisitedTime()}>
        {capitalizeFirstLetter(entity.text)}&nbsp;
      </Link>
    ),
    
  };

  const generateLink = (entity: any) => {
    const linkGenerator = linkGenerators[entity.type];
    return linkGenerator ? linkGenerator(entity) : <>{entity.text}&nbsp;</>;
  };

  const getSourceAsBreadcrumb = (service: string) => {
    switch (service) {
      case "ats":
        return (
          <Breadcrumb
            items={[
              {
                title: (
                  <>
                    {" "}
                    <ProjectOutlined  />{" "}
                    <span>{capitalizeFirstLetter(service)}</span>
                  </>
                ),
              },
              {
                title: (
                  <>
                    <span className="">
                      {props.item.data.deltas.data.board_destination
                        ? props.item.data.deltas.data.board_destination?.name
                        : props.item.data.deltas.data.board?.name}
                    </span>
                  </>
                ),
              },
            ]}
          />
        );

      case "candidates":
        return (
          <Breadcrumb
            items={[
              {
                // href: `/o/${props.applicationId}/db`,

                title: (
                  <>
                    {" "}
                    <ProjectOutlined  />{" "}
                    <span>{capitalizeFirstLetter(service)}</span>
                  </>
                ),
              },
              {
                // href: `/o/${props.applicationId}/b/${props.item.data.deltas.data.board.id}`,
                title: (
                  <>
                    <span>{props.item.data.deltas.data.board.name}</span>
                  </>
                ),
              },
            ]}
          />
        );

      case "billing":
        return (
          <Breadcrumb
            items={[
              {
                // href: `/o/${props.applicationId}/ats`,

                title: (
                  <>
                    {" "}
                    <ProjectOutlined />{" "}
                    <span>{capitalizeFirstLetter(service)}</span>
                  </>
                ),
              },
            ]}
          />
        );
      case "Talent pool":
        return (
          <Breadcrumb
            items={[
              {
                // href: `/o/${props.applicationId}/ats`,

                title: (
                  <>
                    {" "}
                    <DatabaseOutlined  />{" "}
                    <span>{capitalizeFirstLetter(service)}</span>
                  </>
                ),
              },
            ]}
          />
        );

      case "review":
        return (
          <Breadcrumb
            items={[
              {
                // href: `/o/${props.applicationId}/reviewBase`,

                title: (
                  <>
                    {" "}
                    <FileDoneOutlined  />{" "}
                    <span>{capitalizeFirstLetter(service)}</span>
                  </>
                ),
              },
            ]}
          />
        );

      case "JD management":
        return (
          <Breadcrumb
            items={[
              {
                // href: `/o/${props.applicationId}/tmail`,

                title: (
                  <>
                    {" "}
                    <SolutionOutlined  />{" "}
                    <span>{capitalizeFirstLetter(service)}</span>
                  </>
                ),
              },
            ]}
          />
        );

      case "inbox":
        return (
          <Breadcrumb
            items={[
              {
                // href: `/o/${props.applicationId}/tmail`,

                title: (
                  <>
                    {" "}
                    <MailOutlined />{" "}
                    <span>{capitalizeFirstLetter(service)}</span>
                  </>
                ),
              },
            ]}
          />
        );

      case "calendar":
        return (
          <Breadcrumb
            items={[
              {
                // href: `/o/${props.applicationId}/tmail`,

                title: (
                  <>
                    {" "}
                    <CalendarOutlined  />{" "}
                    <span>{capitalizeFirstLetter(service)}</span>
                  </>
                ),
              },
            ]}
          />
        );

      default:
        return (
          <Breadcrumb
            items={[
              {
                // href: '/o/',

                title: (
                  <>
                    {" "}
                    <BulbOutlined />{" "}
                    <span>{service && capitalizeFirstLetter(service)}</span>
                  </>
                ),
              },
            ]}
          />
        );
    }
  };

  const getNotificationComponent = (event: any) => {
    const getUserImage = (url: string) => {
      return (
        <div className="w-max">
          <Avatar
            size={{ xs: 24, sm: 32, md: 36, lg: 38, xl: 42, xxl: 60 }}
            src={url}
          />
        </div>
      );
    };
    const getUserName = (url: string) => {
      return (
        <div className="w-max">
          <Avatar
            size={{ xs: 24, sm: 32, md: 36, lg: 38, xl: 42, xxl: 60 }}
            // src={url[0]}
            style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}  >{capitalizeFirstLetter(url[0])}</Avatar>
        </div>
      );
    };
    const showPreviewImg = (e: any, attachment: any) => {
      e.preventDefault();
      e.stopPropagation();

      showPreview({
        show: !preview.show,
        presignedLink: attachment.presignedLink,
        fileName: attachment.name,
        objectKey: attachment.objectKey,
      });
    };
    switch (event?.data?.event) {
      case "addedAttachment":
        return (
          <>
            <div className="flex  space-x-3 justify-between">
              <div className="flex space-x-3 w-11/12">
                {getUserImage(event?.data?.deltas?.data?.member?.image_url)}
                <div className="flex flex-col w-auto">
                  <div className="flex w-full flex-wrap ">
                    {event.data?.deltas?.entities?.map(
                      (entity: any, index: number) => (
                        <span key={index} className="">
                          {entity.type === "member" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                          {entity.type === "text" && <>{entity.text}&nbsp;</>}
                          {linkGenerators[entity.type] && generateLink(entity)}
                        </span>
                      )
                    )}
                  </div>
                  <div className="text-xs">
                    {getSourceAsBreadcrumb(event.service)}
                  </div>{" "}
                  <div
                    onClick={(e: any) =>
                      showPreviewImg(e, event.data.deltas.data?.attachment)
                    }
                    onKeyDown={() => {}}
                    tabIndex={0}
                    role="button"
                    className="w-max px-2 py-1 mt-2 flex items-center space-x-1 rounded hover:bg-gray-100"
                    style={{
                      boxShadow:
                        "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                    }}
                  >
                    {" "}
                    <div>
                      <AttachmentIcon />
                    </div>{" "}
                    <div data-testid="show-preview">
                      <Link>
                        <div className="text-xs">
                          {" "}
                          {event.data.deltas.data?.attachment?.name}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right  text-xs  w-auto">
                {" "}
                {moment(event.date).format(TIME_FORMATE)}
              </div>
            </div>

            {preview.show ? (
              <AttachmentPreview
                link={preview.presignedLink}
                closePreview={closePreview}
                fileName={preview.fileName}
              />
            ) : null}
          </>
        );
      case "send email":
        return (
          <>
            <div className="flex  space-x-3 justify-between">
              <div className="flex space-x-3 w-11/12">
                <div className="w-max">
                  {event?.data?.deltas?.data?.member?.image_url && (
                    <Avatar
                      size={{ xs: 24, sm: 32, md: 36, lg: 38, xl: 42, xxl: 60 }}
                      src={event?.data?.deltas?.data?.member?.image_url}
                    />
                  )}
                  {!event?.data?.deltas?.data?.member?.image_url && (
                    <Avatar
                      size={{ xs: 24, sm: 32, md: 36, lg: 38, xl: 42, xxl: 60 }}
                    >
                      {event.data.deltas.data?.mail?.from_name
                        .split(" ")
                        .map((word: string) => word.charAt(0).toUpperCase())
                        .join("")}
                    </Avatar>
                  )}
                </div>

                <div className="flex flex-col w-full">
                  <div className="flex w-full flex-wrap ">
                    {event.data?.deltas?.entities?.map(
                      (entity: any, index: number) => (
                        <span key={index} className="">
                          {entity.type === "member" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                          {entity.type === "text" && <>{entity.text}&nbsp;</>}
                          {linkGenerators[entity.type] && generateLink(entity)}
                          {entity.type === "review" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                        </span>
                      )
                    )}
                  </div>
                  <div className="text-xs">
                    {getSourceAsBreadcrumb(event.service)}
                  </div>{" "}
                  <div
                    className="flex items-start  px-2 py-1 mt-2  space-x-2 rounded"
                    style={{
                      boxShadow:
                        "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                    }}
                  >
                    <div className="flex-shrink-0 overflow-hidden w-2/12 text-left font-semibold  text-xs mr-2 ">
                      {capitalizeFirstLetter(
                        event.data.deltas.data?.mail?.from_name
                      )}
                    </div>
                    <div className="flex w-10/12 space-x-2 ">
                      <div className="w-max text-left font-semibold line-clamp-1 text-xs flex-shrink-0">
                        {event.data.deltas.data?.mail?.subject} -
                      </div>
                      <div className=" text-left line-clamp-1 text-xs">
                        {parse(event.data.deltas.data?.mail?.body)}
                      </div>
                    </div>
                  </div>{" "}
                  {/* </div> */}
                </div>
              </div>
              <div className="text-right  text-xs w-auto">
                {" "}
                {moment(event.date).format(TIME_FORMATE)}
              </div>
            </div>
          </>
        );

      case "createEvent":
        return (
          <>
            <div className="flex  space-x-3 justify-between">
              <div className="flex space-x-3 w-11/12">
                {getUserImage(event?.data?.deltas?.data?.member?.image_url)}
                <div className="flex flex-col w-full">
                  <div className="flex w-full flex-wrap ">
                    {event.data?.deltas?.entities?.map(
                      (entity: any, index: number) => (
                        <span key={index} className="">
                          {entity.type === "member" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                          {entity.type === "text" && <>{entity.text}&nbsp;</>}
                          {linkGenerators[entity.type] && generateLink(entity)}
                          {entity.type === "event" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                        </span>
                      )
                    )}
                  </div>
                  <div className="text-xs">
                    {getSourceAsBreadcrumb(event.service)}
                  </div>{" "}
                  <div
                    className="flex items-start   mt-2  space-x-2 rounded"
                    style={{
                      boxShadow:
                        "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                    }}
                  >
                    {" "}
                    <div className="flex  rounded  w-full">
                      <div className=" flex items-center justify-center rounded  border-r bg-gray-100 border-tacitbase-tertiary1 border-opacity-10 p-2">
                        <div className="w-16  ">
                          <div className="bg-white rounded flex-col text-xs ">
                            <div className="bg-tacitbase-secondary3 rounded-t text-center text-white p-0.5">
                              {moment(
                                event?.data?.deltas?.data?.event?.start_date
                              ).format("MMM")}
                            </div>
                            <div className="flex-col text-center p-1">
                              <div className=" font-semibold">
                                {moment(
                                  event?.data?.deltas?.data?.event?.start_date
                                ).format("DD")}
                              </div>
                              <div className=" font-semibold">
                                {moment(
                                  event?.data?.deltas?.data?.event?.start_date
                                ).format("ddd")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-10/12 flex-col">
                        <div className="w-full rounded h-full  p-2">
                          <div className="h-4/12 text-left text-xs font-semibold">
                            {event?.data?.deltas?.data?.event?.summary}{" "}
                          </div>
                          <div className="h-4/12 text-left flex  space-x-2 text-xs">
                            <div className="">When</div>
                            <div>
                              {

                                moment
                                  .utc(
                                    event?.data?.deltas?.data?.event?.start_date
                                  )
                                  .format("dddd MMMM DD, YYYY - HH:mm") +
                                  "-" +
                                  moment
                                    .utc(
                                      event?.data?.deltas?.data?.event
                                        ?.start_date
                                    )
                                    .clone()
                                    .add(
                                      event?.data?.deltas?.data?.event
                                        ?.duration,
                                      "minutes"
                                    )
                                    .format("HH:mm") +
                                  ` (UTC - UTC)`
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right  text-xs w-auto">
                {" "}
                {moment(event.date).format(TIME_FORMATE)}
              </div>
            </div>
          </>
        );
      case "addedComment":
        return (
          <>
            <div className="flex  space-x-3 justify-between">
              <div className="flex space-x-3 w-11/12">
                {getUserImage(event?.data?.deltas?.data?.member?.image_url)}
                <div className="flex flex-col w-auto">
                  <div className="flex w-full flex-wrap ">
                    {event.data?.deltas?.entities?.map(
                      (entity: any, index: number) => (
                        <span key={index} className="">
                          {entity.type === "member" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                          {entity.type === "text" && <>{entity.text}&nbsp;</>}

                          {linkGenerators[entity.type] && generateLink(entity)}
                          {entity.type === "review" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                        </span>
                      )
                    )}
                  </div>
                  <div className="text-xs">
                    {getSourceAsBreadcrumb(event.service)}
                  </div>{" "}
                  <div
                    className="flex items-start  px-2 py-1 mt-2  space-x-1 rounded"
                    style={{
                      boxShadow:
                        "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                    }}
                  >
                    {" "}
                    <div className="w-max">
                      <CommentNewIcon />
                    </div>{" "}
                    <div className="text-left line-clamp-3 text-xs">
                      {parse(event.data.deltas.data?.comment?.name)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-right  text-xs w-auto">
                {" "}
                {moment(event.date).format(TIME_FORMATE)}
              </div>
            </div>
          </>
        );
      case "invite":
        return (
          <>
            <div className="flex  space-x-3 justify-between">
              <div className="flex space-x-3 w-11/12">
                {getUserImage(event?.data?.deltas?.data?.member?.image_url)}
                <div className="flex flex-col w-auto">
                  <div className="flex w-full flex-wrap ">
                    {event.data?.deltas?.entities?.map(
                      (entity: any, index: number) => (
                        <span key={index} className="">
                          {entity.type === "member" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                          {entity.type === "text" && <>{entity.text}&nbsp;</>}

{/* Removing organisation check */}

                          {linkGenerators[entity.type] && generateLink(entity)}
                        </span>
                      )
                    )}
                  </div>
                  <div className="text-xs">
                    {getSourceAsBreadcrumb(
                      event.data?.deltas?.data?.organisation?.name
                    )}
                  </div>
                  <div> </div>
                </div>
              </div>
              <div className="text-right  text-xs w-auto">
                {" "}
                {moment(event.date).format(TIME_FORMATE)}
              </div>
            </div>
          </>
        );
      case "closed":
        return (
          <>
            <div className="flex  space-x-3 justify-between">
              <div className="flex space-x-3 w-11/12">
                {getUserImage(event?.data?.deltas?.data?.member?.image_url)}
                <div className="flex flex-col w-auto">
                  <div className="flex w-full flex-wrap ">
                    {event.data?.deltas?.entities?.map(
                      (entity: any, index: number) => (
                        <span key={index} className="">
                          {entity.type === "member" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                          {entity.type === "text" && <>{entity.text}&nbsp;</>}

                          {entity.type === "review" && (
                            <span className="font-semibold">
                              {entity.text}&nbsp;
                            </span>
                          )}

                          {linkGenerators[entity.type] && generateLink(entity)}
                        </span>
                      )
                    )}
                  </div>
                  <div className="text-xs">
                    {getSourceAsBreadcrumb(event.service)}
                  </div>
                  <div> </div>
                </div>
              </div>
              <div className="text-right  text-xs w-auto">
                {" "}
                {moment(event.date).format(TIME_FORMATE)}
              </div>
            </div>
          </>
        );
        case "createCandidate":
          return (
            <>
              <div className="flex  space-x-3 justify-between">
                <div className="flex space-x-3 w-11/12 text-left truncate">
                  {getUserName(event?.data?.deltas?.data?.candidate?.first_name)}
                  <div className="flex flex-col w-auto ">
                    <div className="flex w-full flex-wrap ">
                      {event.data?.deltas?.entities?.map(
                        (entity: any, index: number) => (
                          <span key={index} className="">
                            {entity.type === "candidate" && (
                              <div>
                                {linkGenerators['candidate'] && generateLink(entity)}
                              </div>
                            )}
                            {entity.type === "text" && <>{entity.text}&nbsp;</>}
                            {entity.type === "job" && (
                              <span >
                                {linkGenerators['job'] && generateLink(entity)}

                              </span>
                            )}
                          </span>
                        )
                      )}
                    </div>
                    <div className="text-xs  ">
                      {getSourceAsBreadcrumb(event.service)}
                    </div>
                    <div> </div>
                  </div>
                </div>
                <div className="text-right  text-xs w-auto">
                  {" "}
                  {moment(event.date).format(TIME_FORMATE)}
                </div>
              </div>
            </>
          );
      default:
        return (
          <>
            <div className="flex  space-x-3 justify-between">
              <div className="flex space-x-3 w-11/12 text-left truncate">
                {getUserImage(event?.data?.deltas?.data?.member?.image_url)}
                <div className="flex flex-col w-auto ">
                  <div className="flex w-full flex-wrap ">
                    {event.data?.deltas?.entities?.map(
                      (entity: any, index: number) => (
                        <span key={index} className="">
                          {entity.type === "member" && (
                            <div className="font-semibold">
                              {entity.text}&nbsp;
                            </div>
                          )}
                          {entity.type === "text" && <>{entity.text}&nbsp;</>}
                          {entity.type === "attachment" && (
                            <span className="font-semibold">
                              {entity.text}&nbsp;
                            </span>
                          )}
                          {entity.type === "list" && (
                            <span className="font-semibold">
                              {entity.text}&nbsp;
                            </span>
                          )}
                          {entity.type === "role" && (
                            <span className="font-semibold">
                              {entity.text}&nbsp;
                            </span>
                          )}
                          {entity.type === "visibility" && (
                            <span className="font-semibold">
                              {entity.text}&nbsp;
                            </span>
                          )}
                          {entity.type === "review" && (
                            <span className="font-semibold">
                              {entity.text}&nbsp;
                            </span>
                          )}
                          {entity.type === "service" && (
                            <span className="font-semibold">
                              {entity.text}&nbsp;
                            </span>
                          )}
                          {entity.type === "mail" && (
                            <span className="font-semibold">
                              {entity.text}&nbsp;
                            </span>
                          )}
                          {entity.type === "event" && (
                            <span className="font-semibold">
                              {entity.text}&nbsp;
                            </span>
                          )}

                          {linkGenerators[entity.type] && generateLink(entity)}
                        </span>
                      )
                    )}
                  </div>
                  <div className="text-xs  ">
                    {getSourceAsBreadcrumb(event.service)}
                  </div>
                  <div> </div>
                </div>
              </div>
              <div className="text-right  text-xs w-auto">
                {" "}
                {moment(event.date).format(TIME_FORMATE)}
              </div>
            </div>
          </>
        );
    }
  };

  return <div className="w-full">{getNotificationComponent(props.item)}</div>;
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps)(NotificationEventHandler);
