import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { getAttachments } from "../../lib/api"
import { Dispatch } from "redux"
import { Attachment, CandidateDetailReview, ReviewAttachments } from "../../type/type"
import { initReviewAttachments } from "../../redux/actions/opening/action"
import { ATTACHMENTS, CANDIDATES } from "../../constant"
import { Space, Tag, Typography } from "antd"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
// import PDFAttachment from "../card/PDFAttachmetnt"
import { FilePdfOutlined } from "@ant-design/icons"
import { PDFRenderer } from "../card/PDFViewer"
import { useParams } from "react-router-dom"
// import PdfViewer from "../common/popupTitle/PDFViewer"
const { Text } = Typography
const DetailedReviewAttachment = (props: {
    applicationId: string,
    currentCandidateInReview: CandidateDetailReview,
    initReviewAttachments: (attachments: Attachment[], reviewId: string) => void,
    reviewAttachments: ReviewAttachments
}) => {
    const [selectedCandidateAttachement, setSelectedAttachment] = useState<Attachment>({
        id: "",
        name: "",
        objectKey: "",
        modelName: "",
        recordID: "",
        presignedLink: "",
        created_at: "",
        updated_at: "",
        opening_id: "",
        converted_presigned_link:"",
        converted_object_key:"",
        parent:"",
    })
    const params: { org_id: string; review_id: string} =
    useParams();

    useEffect(() => {
        params.review_id.length > 0 &&

            getAttachments(
                CANDIDATES,
                params.review_id).then((res) => {
                    props.initReviewAttachments(res, params.review_id)
                })

    }, [params.review_id])
    const handleSelectedAttachment = (attachment: Attachment) => {
        setSelectedAttachment(attachment)
    }
    if (!props.reviewAttachments[props.currentCandidateInReview.id]) return null
    const onCloseViewer = () => {
        setSelectedAttachment({
            id: "",
            name: "",
            objectKey: "",
            modelName: "",
            recordID: "",
            presignedLink: "",
            created_at: "",
            updated_at: "",
            opening_id: "",
            converted_presigned_link:"",
            converted_object_key:"",
            parent:"",

        })
    }
    return (
        <>
            {props.reviewAttachments[props.currentCandidateInReview.id].length > 0 &&
                <Space direction="vertical" className="w-full">
                    <Text strong>{capitalizeFirstLetter(ATTACHMENTS)}</Text>
                    <div className="space-y-2 w-full">
                        {props.reviewAttachments[props.currentCandidateInReview.id]?.map((attachment: Attachment) => (
                            <Tag className="cursor-pointer w-full flex items-center border border-black border-opacity-5 p-2" bordered={false} key={attachment.id} onClick={() => handleSelectedAttachment(attachment)} >
                                <FilePdfOutlined style={{ fontSize: "32px" }} />
                                <Text ellipsis={{ tooltip: attachment.name }} style={{ width: '100%' }}  >
                                    {attachment.name}
                                </Text>
                            </Tag>
                        ))}

                    </div>
                </Space>
            }
            {selectedCandidateAttachement.id.length > 0 &&
                <PDFRenderer title={selectedCandidateAttachement.name} attachmentOfwhom={props.currentCandidateInReview.name} onCloseViewer={onCloseViewer} url={selectedCandidateAttachement.converted_presigned_link}></PDFRenderer>

            }

        </>
    )
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    currentCandidateInReview: state.opening.currentCandidateInReview,
    reviewAttachments: state.opening.reviewAttachments,
})
const mapPropsToState = (dispatch: Dispatch) => ({

    initReviewAttachments: (attachments: Attachment[], reviewId: string) =>
        dispatch(initReviewAttachments(attachments, reviewId)),
})
export default connect(mapStateToProps, mapPropsToState)(DetailedReviewAttachment)
