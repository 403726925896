import React, { useEffect } from "react";
import DomainSetting from "./domainSetting/DomainSetting";
import OrgSetting from "./OrgSetting";
import SlackCommunicationIntegration from "./domainSetting/communicationIntegration/SlackIntegration";
import { Divider, Typography } from "antd";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Integration } from "../../lib/apiRes";
import { addChatIntegration, integratedSlackMember } from "../../redux/actions/opening/action";
import { getIntegratedSlackMember, getOrgintegrations } from "../../lib/api";
import { con } from "../..";
const { Text } = Typography;

type orgSettingIndexProps = {
  addIntegrations: (integration: Integration[]) => void;
};

const OrgSettingIndex = (props:orgSettingIndexProps) => {

   // getting slack member on first rendering
   useEffect(() => {
    getIntegratedSlackMember().then((Slackmembers) => {
      con?.store?.dispatch(integratedSlackMember(Slackmembers));
    });

    getOrgintegrations().then((integration) => {
      props.addIntegrations(integration);
    });
  }, []);

  return (
    <>
      <div className="p-4">
        {" "}
        <Text strong>Organization settings</Text>{" "}
      </div>
      <Divider type="horizontal" className="w-full m-0 " />
      <div
        className="w-full  overflow-y-scroll"
        style={{ height: "calc(-140px + 100vh)" }}
      >
        <div
          className="2xl:w-1/2 xl:3/4 lg:w-3/4 md:w-full sm:w-full h-full flex"
          id="link_1"
        >
          <div className="flex flex-col  w-full">
            <OrgSetting />
            <SlackCommunicationIntegration />
            <DomainSetting />
          </div>
        </div>
      </div>
    </>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  addIntegrations: (integration: Integration[]) =>
    dispatch(addChatIntegration(integration)),
});

export default connect(null,mapPropsToState)(OrgSettingIndex);
