import React from "react";
import { colors, DIVIDER_HEIGHT} from "../../constant";
import { Breadcrumb, Divider } from "antd";
import ContentHeader from "../analyticsVersion1/ContentHeader";
import Navbar from "../navbar/Navbar";
import NavSearch from "../navSearch/NavSearch";
import MailSideBarPannel from "../mail/MailSideBarPannel";


const CandidateReviewBaseHeader = () => {
  return (
    <>
    
    <ContentHeader>
        <div
          className="flex h-12 px-4 py-3  justify-between items-center" >
          <div
            className="flex items-center"          >
            <Breadcrumb
    items={[
      {
        title: 'Mails',
      }
    ]}
  />
          </div>
          <div className="flex flex-row items-center">
            <NavSearch/>
          <Divider type="vertical" style={{height:DIVIDER_HEIGHT,borderColor:`${colors.divider}`}} />

            <Navbar/>
          </div>
        </div>
      </ContentHeader>
      {/* <div className="bg-white">
      <MailSubHeader/>
      </div> */}

      
      <div className="w-full flex h-full flex-col overflow-hidden">
      <MailSideBarPannel/>
      </div>
    </>
  );
};


export default (CandidateReviewBaseHeader);
